import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 100 100">
    <path
      fill={color}
      d="M50 0C22.4211 0 0 22.4211 0 50C0 77.5789 22.4211 100 50 100C77.5789 100 100 77.5789 100 50C100 22.4211 77.5789 0 50 0ZM66.1053 52.5263L42.5263 68.2102C40.5261 69.5788 37.7897 68.1052 37.7897 65.6836V34.3152C37.7897 31.8944 40.5263 30.4207 42.5263 31.7887L66.1053 47.4731C67.8946 48.6311 67.8946 51.3684 66.1053 52.5263Z"
    />
  </svg>
);
